<template>
    <v-container>
       
        
   
        <v-card loading="uploading">
            <v-toolbar flat>
                <v-toolbar-title>反馈</v-toolbar-title>
                <v-divider vertical class="mx-2"></v-divider>
                <v-spacer></v-spacer>
                <v-checkbox label="仅显示有内容" v-model="not_empty"></v-checkbox>
                <v-spacer></v-spacer>
                <v-select 
                    cols="3" 
                    label="包名"
                    :items="formattedPackageItems"
                    v-model="filter_package_name"
                    item-text="text"
                    item-value="value"
                    dense
                    style="max-width: 200px;"
                    ></v-select>

            </v-toolbar>
            <v-data-table
               :headers="headers"
               :items="items"
               :options.sync="options"
               :server-items-length="serverLength"
               :loading="loading"
               loading-text="加载中..."
               :search="search"
               class="elevation-2"
            >
            <template v-slot:item.popupBg="{ item }">
                <v-img @click="viewImage(item.popupBg)" class="rounded-lg" max-width="60" max-height="38" :src="item.popupBg+'?x-oss-process=style/w_200'"/>
           <!-- <v-icon small absolute color="primary">mdi-magnify-plus</v-icon> -->
            </template>
            <template v-slot:item.levelInfo="{ item }">
                <v-chip>{{item.levelInfo.length}}</v-chip>
                <!-- <v-img @click="viewImage(item.collectItem)" class="rounded-lg" max-width="60" max-height="38" :src="item.collectItem+'?x-oss-process=style/w_200'"/> -->
           <!-- <v-icon small absolute color="primary">mdi-magnify-plus</v-icon> -->
            </template>
            <template v-slot:item.country="{ item }">
                {{getFlagEmoji(item.country)}}
                <!-- <v-chip></v-chip> -->
                <!-- <v-img @click="viewImage(item.collectItem)" class="rounded-lg" max-width="60" max-height="38" :src="item.collectItem+'?x-oss-process=style/w_200'"/> -->
           <!-- <v-icon small absolute color="primary">mdi-magnify-plus</v-icon> -->
            </template>
            <template v-slot:item.scrollBg="{ item }">
                <v-img @click="viewImage(item.scrollBg)" class="rounded-lg" max-width="60" max-height="38" :src="item.scrollBg+'?x-oss-process=style/w_200'"/>
           <!-- <v-icon small absolute color="primary">mdi-magnify-plus</v-icon> -->
            </template>
            <template v-slot:item.scrollItem1="{ item }">
                <v-img @click="viewImage(item.scrollItem1)" class="rounded-lg" max-width="60" max-height="38" :src="item.scrollItem1+'?x-oss-process=style/w_200'"/>
           <!-- <v-icon small absolute color="primary">mdi-magnify-plus</v-icon> -->
            </template>
          

            <template v-slot:item.created="{item}">
                <v-chip>{{getTimeStrFromTimestamp(item.created)}}</v-chip>
            </template>
   
            <template v-slot:item.actions="{item}">
                 <v-icon
                   small
                   class="mr-2"
                   @click="editItem(item)"
              >
                   mdi-pencil
              </v-icon>
              <v-icon
                   small
                   @click="deleteItem(item)"
              >
                   mdi-delete
              </v-icon>
            </template>
   
            </v-data-table>
        </v-card>
    </v-container>
   </template>
   
   <script>
   import axios from 'axios'
   import {bus} from '@/main'
   
   export default ({
       name : "FeedbackList",
       components: {
           
       },
       data: function(){
           return {
               headers: [
                   {
                       text: "应用名称",
                       align: "center",
                       sortable: false,
                       value: "app_name",
                       width: "10%",
                   },
                   {
                       text: "平台",
                       align: "center",
                       sortable: false,
                       value: "platform",
                       width: "10%",
                   },
                   {
                       text: "包名",
                       align: "center",
                       sortable: false,
                       value: "package_name",
                       width: "5%",
                   },
                   {
                       text: "版本号",
                       align: "center",
                       sortable: false,
                       value: "version",
                       width: "5%",
                   },
                   {
                       text: "语言",
                       align: "center",
                       sortable: false,
                       value: "language",
                       width: "5%",
                   },
                   {
                       text: "设备型号",
                       align: "center",
                       sortable: false,
                       value: "device_model",
                       width: "5%",
                   },                
                   {
                       text: "国家",
                       align: "center",
                       sortable: false,
                       value: "country",
                       width: "10%",
                   },
                   {
                       text: "s_id",
                       align: "center",
                       sortable: false,
                       value: "s_id",
                       width: "10%",
                   },
                   {
                       text: "评星",
                       align: "center",
                       sortable: false,
                       value: "stars",
                       width: "10%",
                   },
                   {
                       text: "feedback",
                       align: "center",
                       sortable: false,
                       value: "feedback",
                       width: "10%",
                   },
                   {
                       text: "时间",
                       align: "center",
                       sortable: false,
                       value: "created",
                       width: "10%",
                   }
                   
               ],
               loading:false,
               tabs: null,
               tabdata: null,
               packageItems: [],
               not_empty: false,
               eventDailog: false,
               uploading:false,
               editLang: '',
               filter_package_name: '',
               startMenu:false,
               dialogDelete: false,
               imageViewer: false,
               imageViewerUrl: "",
               search: "",
               options:{},
               items:[],
               endMenu:false,
               serverLength:0,
               editLocalName: '',
               isModifying: false,
               levelInfoJsonStr: '',
               premiumRewardJsonStr: '',
               
           }
       },
       computed: {
           //  isFreeLable:function(){
           //     return this.itemEdit.isFree ? "免费": "收费";
           // },

           formattedPackageItems: function() {
            return this.packageItems.map(item => ({
                text: `${item._id} (${item.count})`,
                value: item._id,
            }));
            },


           dialogTitle: function(){
               return this.isModifying ? "修改":"新建";
           },
           // dialogTitle: function(){
           //     return this.isModifying?"修改Picture":"新建Picture";
           // },
       },

    

       watch: {
           options:{
               handler(){
                   this.reloadData();
               },
               deep: true,
           },
           not_empty: function(){
               this.reloadData();
               this.countGroupByPackageName();
           },
            filter_package_name: function(){
                console.log("filter_package_name changed " + this.filter_package_name);
                this.reloadData();
            },
           levelInfoJsonStr: function(){
               try{
                   this.itemEdit.levelInfo = JSON.parse(this.levelInfoJsonStr);
               }catch(e){
                   bus.$emit("showSnackMessage", "json格式错误 ", true)
                   this.levelInfoJsonStr = JSON.stringify(this.itemEdit.levelInfo);
                   console.log(e);
               }
           },
            premiumRewardJsonStr: function(){
            try{
                    this.itemEdit.premiumReward = JSON.parse(this.premiumRewardJsonStr);
            }catch(e){
                    bus.$emit("showSnackMessage", "json格式错误 ", true)
                    this.premiumRewardJsonStr = JSON.stringify(this.itemEdit.premiumReward);
                    console.log(e);
            }
            },
   
       },
   
       methods: {
           // addEditLevelToEditPack: function(){
           //     this.packItemEdit.levels.push({...this.levelEdit})
           // },
           viewImage(url){
               this.imageViewerUrl = url;
               this.imageViewer = true;
           },
           clearitemEdit: function(){
               this.isModifying = false;
               this.itemEdit = {
                   _id: '',
                   journeyId: '',
                   journeyName: '',
                   popupBg: '',
                   collectItem: '',
                   scrollBg: '',
                   scrollItem1: '',
                   scrollItem2: '',
                   scrollItem3: '',
                   badge1: '',
                   badge2: '',
                   badge3: '',
                   levelInfo: [],
               };
               this.refreshJsonStr();
           },
           allowedDates:function(val){
                var day = new Date(val);
                return day.getDay() === 5;
           },
           refreshJsonStr: function(){
                this.levelInfoJsonStr = JSON.stringify(this.itemEdit.levelInfo, null, 2);
                this.premiumRewardJsonStr = JSON.stringify(this.itemEdit.premiumReward, null, 2);
            },
           modEventMeta: function(eventItem){
               this.itemEdit = Object.assign({}, eventItem);
               this.isModifying =true;
               this.eventDailog = true;
           },
           getIsFreeColor: function(isFree){
               return isFree?"green":"orange";
           },
           deleteLocalName: function(name){
               console.log(name);
               this.$delete(this.itemEdit.localName, name);
           },
           getFlagEmoji : countryCode=>String.fromCodePoint(...[...countryCode.toUpperCase()].map(x=>0x1f1a5+x.charCodeAt())),
           getTimeStrFromTimestamp(timestamp){
               var s = new Date(timestamp)
               return s.toLocaleString("zh-CN")
           },
           getIsFreeContent: function(isFree){
               return isFree?"免费":"付费";
           },
           addEventDailog: function(){
               this.clearitemEdit();
               this.eventDailog = true;
           },
   
           modCollection: function(col){
               this.itemEdit = col;
               this.refreshJsonStr();
               this.isModifying = true;
               this.eventDailog = true;
               console.log(col);
           },
   
           delCollection: function(col){
               this.itemEdit = col;
               this.refreshJsonStr();
               this.isModifying = true;
               this.delCol();
               console.log(col);
           },
           countGroupByPackageName: function(){
                var vm = this;
                axios.get("/admin/api/feedback/countGroupByPackageName?not_empty="+vm.not_empty).then(function(response){
                   if(response.data.status == 0){
                       console.log(response);
                       vm.packageItems = response.data.data;
                    //    vm.items = response.data.data.list;
                    //    vm.loading=false;        
                    //    vm.serverLength = response.data.data.totalCnt;
                   }else{
                        console.log(response.data.status);
                       bus.$emit("showSnackMessage", "刷新event数据失败" + response.data.msg, true);
                   }
               }).catch(function(err){
                   bus.$emit("showSnackMessage", "刷新collection数据失败，出现异常 ：" +err);
               })
           },
           fileuploadChange: function(files, point){
               console.log(files)
               console.log(point)
               var formData = new FormData();
               formData.append("image", files);
               formData.append("product", "zenmahjong")
               var vm = this;
               axios.post("/admin/api/uploadFile", formData, {
                   headers: {
                       'Content-Type': 'multipart/form-data'
                   }
               }).then(function(response){
                   if(response.data.status == 0){
                       vm.itemEdit[point] = response.data.data.url;
                       //vm.itemEdit.hash = response.data.data.hash;
                   }else{
                       bus.$emit("showSnackMessage", "图片上传失败 " + response.data.msg, true)
                   }
                 
               }).catch(function(){
                   bus.$emit("showSnackMessage", "图片上传失败 ", true)
                   console.log("upload failed");
   
               })
           },
   
           editItem: function(item){
               var index = this.items.indexOf(item);
               if (index !== -1){
                   this.isModifying = true;
                   this.itemEdit = Object.assign({}, item);
                   this.refreshJsonStr();
                   this.eventDailog = true;
               }else{
                   this.isModifying = false;
               }
              
           },
   
           
   
           
           reloadData: function(){
               console.log("will reload data");
               var vm = this;
               const { page, itemsPerPage } = this.options
               axios.get("/admin/api/feedback/listFeedback?page="+(page-1)+'&step='+itemsPerPage+"&package_name=" + vm.filter_package_name + "&not_empty="+vm.not_empty).then(function(response){
                   if(response.data.status == 0){
                       console.log(response);
                       vm.items = response.data.data.list;
                       vm.loading=false;        
                       vm.serverLength = response.data.data.totalCnt;
                   }else{
                        console.log(response.data.status);
                       bus.$emit("showSnackMessage", "刷新event数据失败" + response.data.msg, true);
                   }
               }).catch(function(err){
                   bus.$emit("showSnackMessage", "刷新collection数据失败，出现异常 ：" +err);
               })
           },
           DailogSave: function(){
               this.realAddEvent(this.itemEdit);           
           },
           DailogCancel: function(){
               this.eventDailog = false;
               this.clearitemEdit();
           },
           isFreeChange: function(){
               this.itemEdit.coins = 0;
               this.itemEdit.price = 0;
               this.itemEdit.IAPId = "";
               
           }
   
       },


       mounted: function(){
           this.reloadData();
           this.countGroupByPackageName();
       },
   })
   </script>
   
   <style >
   .v-tab {
   text-transform: none !important;
   }
   </style>   