<template>
    <v-container>
        <v-dialog width="800px" v-model="imageViewer">
            <v-card>
                <v-img :src="imageViewerUrl"></v-img>
            </v-card>

        </v-dialog>
        <v-dialog v-model="eventDailog" max-width="1000px" persistent>
            <v-card>
                <v-card-title>{{dialogTitle}}旅行活动</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-row dense>
                            <v-col cols="5">
                               <v-menu
                                   v-model="startMenu"
                                   :close-on-content-click="false"
                                   :nudge-right="40"
                                   transition="scale-transition"
                                   offset-y
                                   min-width="auto"
                               >
                               <template v-slot:activator="{ on, attrs }">
                                   <v-text-field
                                       v-model="itemEdit.journeyId"
                                       label="开始日期(活动ID)"
                                       prepend-icon="mdi-calendar"
                                       readonly
                                       v-bind="attrs"
                                       v-on="on"
                                   >                                    
                                   </v-text-field>
                               </template>
                               <v-date-picker
                                   show-adjacent-months
                                   v-model="itemEdit.journeyId"
                                   @input="startMenu = false"
                                   :allowed-dates="allowedDates"
                               >                                     
                               </v-date-picker>
                               
                               </v-menu>
                           </v-col>
                            <v-col cols="3">
                                <v-text-field v-model="itemEdit.journeyName" label="旅行名称"></v-text-field>
                            </v-col>
                      
                        </v-row>                    
                       <v-row dense>
                           
                           <v-spacer></v-spacer>
                           <!-- <v-col cols="5">
                               <v-menu
                                   v-model="endMenu"
                                   :close-on-content-click="false"
                                   :nudge-right="40"
                                   transition="scale-transition"
                                   offset-y
                                   min-width="auto"
                               >
                               <template v-slot:activator="{ on, attrs }">
                                   <v-text-field
                                       v-model="itemEdit.endTime"
                                       label="结束日期"
                                       prepend-icon="mdi-calendar"
                                       readonly
                                       v-bind="attrs"
                                       v-on="on"
                                   >                                    
                                   </v-text-field>
                               </template>
                               <v-date-picker
                                   v-model="itemEdit.endTime"
                                   @input="endMenu = false"
                               >                                     
                               </v-date-picker>
                               
                               </v-menu>
                           </v-col> -->
                       </v-row>
                       <!-- <v-row>
                         <v-col cols="3">
                            <v-text-field v-model="itemEdit.levelInfo" label="关卡配置"></v-text-field>
                         </v-col>
                       </v-row> -->
                       <v-row dense>
                            <v-col cols="3">
                                <v-file-input
                                    label="弹窗背景图"
                                    accept="image/png, image/jpeg, image/bmp"
                                    prepend-icon="mdi-camera"
                                    @change="(files) => fileuploadChange(files, 'popupBg')"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-chip
                                            small
                                            label
                                            color="primary"
                                        >{{ text }}</v-chip>
                                    </template>
                                </v-file-input>
                                                       <!-- <v-text-field v-model="packEdit.img" label="底图"></v-text-field>                                 -->
                            </v-col>
                            <v-col cols="3">
                                <v-file-input
                                    label="搜集物"
                                    accept="image/png, image/jpeg, image/bmp"
                                    prepend-icon="mdi-camera"
                                    @change="(files) => fileuploadChange(files, 'collectItem')"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-chip
                                            small
                                            label
                                            color="primary"
                                        >{{ text }}</v-chip>
                                    </template>
                                </v-file-input>
                                                       <!-- <v-text-field v-model="packEdit.img" label="底图"></v-text-field>                                 -->
                            </v-col>
                            <v-col cols="3">
                                <v-file-input
                                    label="底部装饰BG"
                                    accept="image/png, image/jpeg, image/bmp"
                                    prepend-icon="mdi-camera"
                                    @change="(files) => fileuploadChange(files, 'scrollBg')"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-chip
                                            small
                                            label
                                            color="primary"
                                        >{{ text }}</v-chip>
                                    </template>
                                </v-file-input>
                                                       <!-- <v-text-field v-model="packEdit.img" label="底图"></v-text-field>                                 -->
                            </v-col>
                       </v-row>
                       <v-row dense>
                            <v-col cols="3">
                                <v-file-input
                                    label="地图装饰1"
                                    accept="image/png, image/jpeg, image/bmp"
                                    prepend-icon="mdi-camera"
                                    @change="(files) => fileuploadChange(files, 'scrollItem1')"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-chip
                                            small
                                            label
                                            color="primary"
                                        >{{ text }}</v-chip>
                                    </template>
                                </v-file-input>
                                                       <!-- <v-text-field v-model="packEdit.img" label="底图"></v-text-field>                                 -->
                            </v-col>
                            <v-col cols="3">
                                <v-file-input
                                    label="地图装饰2"
                                    accept="image/png, image/jpeg, image/bmp"
                                    prepend-icon="mdi-camera"
                                    @change="(files) => fileuploadChange(files, 'scrollItem2')"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-chip
                                            small
                                            label
                                            color="primary"
                                        >{{ text }}</v-chip>
                                    </template>
                                </v-file-input>
                                                       <!-- <v-text-field v-model="packEdit.img" label="底图"></v-text-field>                                 -->
                            </v-col>
                            <v-col cols="3">
                                <v-file-input
                                    label="地图装饰3"
                                    accept="image/png, image/jpeg, image/bmp"
                                    prepend-icon="mdi-camera"
                                    @change="(files) => fileuploadChange(files, 'scrollItem3')"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-chip
                                            small
                                            label
                                            color="primary"
                                        >{{ text }}</v-chip>
                                    </template>
                                </v-file-input>
                                                       <!-- <v-text-field v-model="packEdit.img" label="底图"></v-text-field>                                 -->
                            </v-col>
                           
                       </v-row>

                       <v-row dense>
                            <v-col cols="3">
                                <v-file-input
                                    label="徽章1"
                                    accept="image/png, image/jpeg, image/bmp"
                                    prepend-icon="mdi-camera"
                                    @change="(files) => fileuploadChange(files, 'badge1')"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-chip
                                            small
                                            label
                                            color="primary"
                                        >{{ text }}</v-chip>
                                    </template>
                                </v-file-input>
                                                       <!-- <v-text-field v-model="packEdit.img" label="底图"></v-text-field>                                 -->
                            </v-col>
                            <v-col cols="3">
                                <v-file-input
                                    label="徽章2"
                                    accept="image/png, image/jpeg, image/bmp"
                                    prepend-icon="mdi-camera"
                                    @change="(files) => fileuploadChange(files, 'badge2')"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-chip
                                            small
                                            label
                                            color="primary"
                                        >{{ text }}</v-chip>
                                    </template>
                                </v-file-input>
                                                       <!-- <v-text-field v-model="packEdit.img" label="底图"></v-text-field>                                 -->
                            </v-col>
                            <v-col cols="3">
                                <v-file-input
                                    label="徽章3"
                                    accept="image/png, image/jpeg, image/bmp"
                                    prepend-icon="mdi-camera"
                                    @change="(files) => fileuploadChange(files, 'badge3')"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-chip
                                            small
                                            label
                                            color="primary"
                                        >{{ text }}</v-chip>
                                    </template>
                                </v-file-input>
                                                       <!-- <v-text-field v-model="packEdit.img" label="底图"></v-text-field>                                 -->
                            </v-col>
                            
                           
                       </v-row>


                       <v-divider></v-divider>
                       <v-row dense>
                            <v-textarea
                                    v-model="levelInfoJsonStr"
                                    label="关卡配置"
                                    outlined
                                    rows="1"
                                    auto-grow
                            ></v-textarea>                              
                       </v-row>
                       <v-row dense>
                            <v-textarea
                                    v-model="i18nJsonStr"
                                    label="名称国际化"
                                    outlined
                                    rows="1"
                                    auto-grow
                            ></v-textarea>                              
                       </v-row>
                       <!-- <v-row dense>
                            <v-textarea
                                    v-model="premiumRewardJsonStr"
                                    label="付费奖励"
                                    outlined
                                    rows="1"
                                    auto-grow
                            ></v-textarea>                              
                       </v-row> -->
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" @click="DailogCancel" text>取消</v-btn>
                    <v-btn color="blue darken-1" @click="DailogSave" text>确定</v-btn>
                </v-card-actions>
            </v-card>
   
        </v-dialog>
   
        <v-card loading="uploading">
            <v-toolbar flat>
                <v-toolbar-title>旅行</v-toolbar-title>
                <v-divider vertical class="mx-2"></v-divider>
                <v-spacer></v-spacer>
                <v-btn outlined color="grey darken-2" @click="addEventDailog">新增</v-btn>
            </v-toolbar>
            <v-data-table
               :headers="headers"
               :items="items"
               :options.sync="options"
               :server-items-length="serverLength"
               :loading="loading"
               loading-text="加载中..."
               :search="search"
               class="elevation-2"
            >
            <template v-slot:item.popupBg="{ item }">
                <v-img @click="viewImage(item.popupBg)" class="rounded-lg" max-width="60" max-height="38" :src="item.popupBg+'?x-oss-process=style/w_200'"/>
           <!-- <v-icon small absolute color="primary">mdi-magnify-plus</v-icon> -->
            </template>
            <template v-slot:item.levelInfo="{ item }">
                <v-chip>{{item.levelInfo.length}}</v-chip>
                <!-- <v-img @click="viewImage(item.collectItem)" class="rounded-lg" max-width="60" max-height="38" :src="item.collectItem+'?x-oss-process=style/w_200'"/> -->
           <!-- <v-icon small absolute color="primary">mdi-magnify-plus</v-icon> -->
            </template>
            <template v-slot:item.i18n="{ item }">
                <v-chip>{{item.i18n && Object.keys(item.i18n).length}}</v-chip>
                <!-- <v-img @click="viewImage(item.collectItem)" class="rounded-lg" max-width="60" max-height="38" :src="item.collectItem+'?x-oss-process=style/w_200'"/> -->
           <!-- <v-icon small absolute color="primary">mdi-magnify-plus</v-icon> -->
            </template>
            <template v-slot:item.collectItem="{ item }">
                <v-img @click="viewImage(item.collectItem)" class="rounded-lg" max-width="60" max-height="38" :src="item.collectItem+'?x-oss-process=style/w_200'"/>
           <!-- <v-icon small absolute color="primary">mdi-magnify-plus</v-icon> -->
            </template>
            <template v-slot:item.scrollBg="{ item }">
                <v-img @click="viewImage(item.scrollBg)" class="rounded-lg" max-width="60" max-height="38" :src="item.scrollBg+'?x-oss-process=style/w_200'"/>
           <!-- <v-icon small absolute color="primary">mdi-magnify-plus</v-icon> -->
            </template>
            <template v-slot:item.scrollItem1="{ item }">
                <v-img @click="viewImage(item.scrollItem1)" class="rounded-lg" max-width="60" max-height="38" :src="item.scrollItem1+'?x-oss-process=style/w_200'"/>
           <!-- <v-icon small absolute color="primary">mdi-magnify-plus</v-icon> -->
            </template>
            <template v-slot:item.scrollItem2="{ item }">
                <v-img @click="viewImage(item.scrollItem2)" class="rounded-lg" max-width="60" max-height="38" :src="item.scrollItem2+'?x-oss-process=style/w_200'"/>
           <!-- <v-icon small absolute color="primary">mdi-magnify-plus</v-icon> -->
            </template>
            <template v-slot:item.scrollItem3="{ item }">
                <v-img @click="viewImage(item.scrollItem3)" class="rounded-lg" max-width="60" max-height="38" :src="item.scrollItem3+'?x-oss-process=style/w_200'"/>
           <!-- <v-icon small absolute color="primary">mdi-magnify-plus</v-icon> -->
            </template>
            <template v-slot:item.badge1="{ item }">
                <v-img @click="viewImage(item.badge1)" class="rounded-lg" max-width="60" max-height="38" :src="item.badge1+'?x-oss-process=style/w_200'"/>
           <!-- <v-icon small absolute color="primary">mdi-magnify-plus</v-icon> -->
            </template>
            <template v-slot:item.badge2="{ item }">
                <v-img @click="viewImage(item.badge2)" class="rounded-lg" max-width="60" max-height="38" :src="item.badge2+'?x-oss-process=style/w_200'"/>
           <!-- <v-icon small absolute color="primary">mdi-magnify-plus</v-icon> -->
            </template>
            <template v-slot:item.badge3="{ item }">
                <v-img @click="viewImage(item.badge3)" class="rounded-lg" max-width="60" max-height="38" :src="item.badge3+'?x-oss-process=style/w_200'"/>
           <!-- <v-icon small absolute color="primary">mdi-magnify-plus</v-icon> -->
            </template>



            <template v-slot:item.modifyTime="{item}">
                <v-chip>{{getTimeStrFromTimestamp(item.modifyTime)}}</v-chip>
            </template>
   
            <template v-slot:item.actions="{item}">
                 <v-icon
                   small
                   class="mr-2"
                   @click="editItem(item)"
              >
                   mdi-pencil
              </v-icon>
              <v-icon
                   small
                   @click="deleteItem(item)"
              >
                   mdi-delete
              </v-icon>
            </template>
   
            </v-data-table>
        </v-card>
    </v-container>
   </template>
   
   <script>
   import axios from 'axios'
   import {bus} from '@/main'
   
   export default ({
       name : "ZenMahjongConfig",
       components: {
           
       },
       data: function(){
           return {
               headers: [
                   {
                       text: "旅行ID",
                       align: "center",
                       sortable: false,
                       value: "journeyId",
                       width: "10%",
                   },
                   {
                       text: "旅行名称",
                       align: "center",
                       sortable: false,
                       value: "journeyName",
                       width: "10%",
                   },
                   {
                       text: "活动关卡配置",
                       align: "center",
                       sortable: false,
                       value: "levelInfo",
                       width: "5%",
                   },
                   {
                       text: "翻译配置",
                       align: "center",
                       sortable: false,
                       value: "i18n",
                       width: "5%",
                   },
                   {
                       text: "弹窗背景图",
                       align: "center",
                       sortable: false,
                       value: "popupBg",
                       width: "5%",
                   },
                   {
                       text: "搜集物",
                       align: "center",
                       sortable: false,
                       value: "collectItem",
                       width: "5%",
                   },
                   {
                       text: "底部装饰BG",
                       align: "center",
                       sortable: false,
                       value: "scrollBg",
                       width: "5%",
                   },                
                   {
                       text: "地图装饰1",
                       align: "center",
                       sortable: false,
                       value: "scrollItem1",
                       width: "10%",
                   },
                   {
                       text: "地图装饰2",
                       align: "center",
                       sortable: false,
                       value: "scrollItem2",
                       width: "10%",
                   },
                   {
                       text: "地图装饰3",
                       align: "center",
                       sortable: false,
                       value: "scrollItem3",
                       width: "10%",
                   },
                   {
                       text: "徽章1",
                       align: "center",
                       sortable: false,
                       value: "badge1",
                       width: "10%",
                   },
                   {
                       text: "徽章2",
                       align: "center",
                       sortable: false,
                       value: "badge2",
                       width: "10%",
                   },
                   {
                       text: "徽章3",
                       align: "center",
                       sortable: false,
                       value: "badge3",
                       width: "10%",
                   },
                   {
                       text: "修改日期",
                       align: "center",
                       sortable: false,
                       value: "modifyTime",
                       width: "10%",
                   },
                   {
                       text: "操作",
                       align: "center",
                       sortable: false,
                       value: "actions",
                       width: "10%",
   
                   },
               ],
               loading:false,
               tabs: null,
               tabdata: null,
               eventDailog: false,
               uploading:false,
               editLang: '',
               startMenu:false,
               dialogDelete: false,
               imageViewer: false,
               imageViewerUrl: "",
               search: "",
               options:{},
               items:[],
               endMenu:false,
               serverLength:0,
               editLocalName: '',
               isModifying: false,
               levelInfoJsonStr: '[]',
               i18nJsonStr: '{}',
               premiumRewardJsonStr: '',
               itemEdit:{
                   _id: '',
                   journeyId: '',
                   journeyName: '',
                   i18n: {},
                   popupBg: '',
                   collectItem: '',
                   scrollBg: '',
                   scrollItem1: '',
                   scrollItem2: '',
                   scrollItem3: '',
                   badge1: '',
                   badge2: '',
                   badge3: '',
                   levelInfo: [],

               }
           }
       },
       computed: {
           //  isFreeLable:function(){
           //     return this.itemEdit.isFree ? "免费": "收费";
           // },
           dialogTitle: function(){
               return this.isModifying ? "修改":"新建";
           },
           // dialogTitle: function(){
           //     return this.isModifying?"修改Picture":"新建Picture";
           // },
       },

    

       watch: {
           options:{
               handler(){
                   this.reloadData();
               },
               deep: true,
           },
           levelInfoJsonStr: function(){
               try{
                   this.itemEdit.levelInfo = JSON.parse(this.levelInfoJsonStr);
               }catch(e){
                   bus.$emit("showSnackMessage", "json格式错误 ", true)
                   this.levelInfoJsonStr = JSON.stringify(this.itemEdit.levelInfo);
                   console.log(e);
               }
           },
           i18nJsonStr: function(){
               try{
                   this.itemEdit.i18n = JSON.parse(this.i18nJsonStr);
               }catch(e){
                   bus.$emit("showSnackMessage", "json格式错误 ", true)
                   this.i18nJsonStr = JSON.stringify(this.itemEdit.i18n);
                   console.log(e);
               }
            },
            premiumRewardJsonStr: function(){
            try{
                    this.itemEdit.premiumReward = JSON.parse(this.premiumRewardJsonStr);
            }catch(e){
                    bus.$emit("showSnackMessage", "json格式错误 ", true)
                    this.premiumRewardJsonStr = JSON.stringify(this.itemEdit.premiumReward);
                    console.log(e);
            }
            },
   
       },
   
       methods: {
           // addEditLevelToEditPack: function(){
           //     this.packItemEdit.levels.push({...this.levelEdit})
           // },
           viewImage(url){
               this.imageViewerUrl = url;
               this.imageViewer = true;
           },
           clearitemEdit: function(){
               this.isModifying = false;
               this.itemEdit = {
                   _id: '',
                   journeyId: '',
                   journeyName: '',
                   popupBg: '',
                   collectItem: '',
                   scrollBg: '',
                   scrollItem1: '',
                   scrollItem2: '',
                   scrollItem3: '',
                   badge1: '',
                   badge2: '',
                   badge3: '',
                   i18n: {},
                   levelInfo: [],
               };
               this.refreshJsonStr();
           },
           allowedDates:function(val){
                var day = new Date(val);
                return day.getDay() === 5;
           },
           refreshJsonStr: function(){
                this.levelInfoJsonStr = JSON.stringify(this.itemEdit.levelInfo, null, 2);
                this.i18nJsonStr = JSON.stringify(this.itemEdit.i18n, null, 2);
                this.premiumRewardJsonStr = JSON.stringify(this.itemEdit.premiumReward, null, 2);
            },
           modEventMeta: function(eventItem){
               this.itemEdit = Object.assign({}, eventItem);
               this.isModifying =true;
               this.eventDailog = true;
           },
           getIsFreeColor: function(isFree){
               return isFree?"green":"orange";
           },
           deleteLocalName: function(name){
               console.log(name);
               this.$delete(this.itemEdit.localName, name);
           },
           getTimeStrFromTimestamp(timestamp){
               var s = new Date(timestamp * 1000)
               return s.toLocaleString("zh-CN")
           },
           getIsFreeContent: function(isFree){
               return isFree?"免费":"付费";
           },
           addEventDailog: function(){
               this.clearitemEdit();
               this.eventDailog = true;
           },
   
           modCollection: function(col){
               this.itemEdit = col;
               this.refreshJsonStr();
               this.isModifying = true;
               this.eventDailog = true;
               console.log(col);
           },
   
           delCollection: function(col){
               this.itemEdit = col;
               this.refreshJsonStr();
               this.isModifying = true;
               this.delCol();
               console.log(col);
           },
           fileuploadChange: function(files, point){
               console.log(files)
               console.log(point)
               var formData = new FormData();
               formData.append("image", files);
               formData.append("product", "zenmahjong")
               var vm = this;
               axios.post("/admin/api/uploadFile", formData, {
                   headers: {
                       'Content-Type': 'multipart/form-data'
                   }
               }).then(function(response){
                   if(response.data.status == 0){
                       vm.itemEdit[point] = response.data.data.url;
                       //vm.itemEdit.hash = response.data.data.hash;
                   }else{
                       bus.$emit("showSnackMessage", "图片上传失败 " + response.data.msg, true)
                   }
                 
               }).catch(function(){
                   bus.$emit("showSnackMessage", "图片上传失败 ", true)
                   console.log("upload failed");
   
               })
           },
   
           editItem: function(item){
               var index = this.items.indexOf(item);
               if (index !== -1){
                   this.isModifying = true;
                   this.itemEdit = Object.assign({}, item);
                   this.refreshJsonStr();
                   this.eventDailog = true;
               }else{
                   this.isModifying = false;
               }
              
           },
   
           deleteItem: function(item){
               console.log("delete item clicked");
               this.itemEdit = Object.assign({}, item);
               this.dialogDelete = true;
               var vm = this;
               this.$confirm("确定要删除?").then(res => {
                   if(res === true){
                       var form = new FormData();
                       form.append("_id", this.itemEdit._id);
                       axios.post("/admin/api/zenmahjong/delEvent", form, {
                           headers: {
                                   'Content-Type': 'application/x-www-form-urlencoded'
                           }
                       }).then(function(response){
                           if(response.data.status == 0){
                                bus.$emit("删除成功");
                                vm.clearitemEdit();
                                vm.isModifying = false;
                                vm.reloadData();
                           }else{
                               bus.$emit("showSnackMessage", "删除错误: " + response.data.msg, true);                      
                           }
                       }).catch(function(err){
                           bus.$emit("showSnackMessage", "错误发生: " + err, true);
                       })
                   }
   
               })
           },
   
           realAddEvent: function(eventItem){
               this.uploading = true;
               var vm = this;
               //eventItem.levelCnt = eventItem.levels.length;
               var formData = new FormData();
               formData.append("data", JSON.stringify(eventItem));
               console.log(formData);
            //    for ( var key in eventItem ) {
            //        var item = eventItem[key];
            //        if (key == "packages"){
            //            formData.append(key, JSON.stringify(item));
            //            continue;
            //        }
   
                   
            //        // if (Array.isArray(item)){
            //        //     for(var index in item){
            //        //         formData.append(key, item[index])
            //        //     }
            //        // }else 
                   
            //        if (typeof item === "object" && item != null){
            //            formData.append(key, JSON.stringify(item))
            //        }else{
            //            formData.append(key, item)
            //        }
                   
            //    }
               axios.post('/admin/api/zenmahjong/addEvent', formData, {
                   headers: {
                           'Content-Type': 'application/x-www-form-urlencoded'
                   }
               }).then(function(response){              
                   console.log(response);
                   vm.clearitemEdit();
                   if(response.data.status == 0){
                       vm.isModifying = false;
                       vm.eventDailog = false;
                       vm.uploading = false;
                       bus.$emit("showSnackMessage", "新增成功")
                       vm.reloadData();
                   }else{
                       bus.$emit("showSnackMessage", "新增/修改失败 " + response.data.status + " " + response.data.msg, true);
                       vm.uploading = false;
                   }
                   
               }).catch(function(error){
                   console.log(error);
                   vm.uploading = false;
                   bus.$emit("showSnackMessage", "新增失败" + error, true)
                   vm.reloadData();
               });
           },
   
           
           reloadData: function(){
               console.log("will reload data");
               var vm = this;
               const { page, itemsPerPage } = this.options
               axios.get("/admin/api/zenmahjong/listEvent?page="+(page-1)+'&step='+itemsPerPage).then(function(response){
                   if(response.data.status == 0){
                       console.log(response);
                       vm.items = response.data.data.list;
                       vm.loading=false;        
                       vm.serverLength = response.data.data.totalCnt;
                   }else{
                        console.log(response.data.status);
                       bus.$emit("showSnackMessage", "刷新event数据失败" + response.data.msg, true);
                   }
               }).catch(function(err){
                   bus.$emit("showSnackMessage", "刷新collection数据失败，出现异常 ：" +err);
               })
           },
           DailogSave: function(){
               this.realAddEvent(this.itemEdit);           
           },
           DailogCancel: function(){
               this.eventDailog = false;
               this.clearitemEdit();
           },
           isFreeChange: function(){
               this.itemEdit.coins = 0;
               this.itemEdit.price = 0;
               this.itemEdit.IAPId = "";
               
           }
   
       },
   
       mounted: function(){
           this.reloadData();
       },
   })
   </script>
   
   <style >
   .v-tab {
   text-transform: none !important;
   }
   </style>   