<template>
    <v-container>

    <v-card class="ma-3">
        <v-tabs v-model="tab" grow>
            <v-tab>用户反馈</v-tab>
            <!-- <v-tab>用户浏览</v-tab> -->
        </v-tabs>
        <v-card class="ma-3">
            <v-tabs-items v-model="tab" class="mt-3" flat>
                <v-tab-item>
                    <feedback-list/>
                </v-tab-item>
                <!-- <v-tab-item>
                    <poolclash-userprofile/>
                </v-tab-item> -->
            </v-tabs-items>
        </v-card>
    </v-card>
</v-container>
</template>

<script>
import FeedbackList from '@/components/feedback/FeedbackList.vue'
//import PoolClashUserProfile from '@/components/poolclash/PoolClashUserProfile.vue'
// import axios from 'axios'
// import RegionUtils from '@/utils/regionUtils'
// import {bus} from '@/main'

export default ({
   name : "Feedback",
   components: {
       'feedback-list' : FeedbackList,
       //'poolclash-userprofile' : PoolClashUserProfile,
   },

   data: function(){
       return {
           tab: 0,
       }
   },
   
   
})
</script>

<style >
.v-tab {
text-transform: none !important;
}
</style>   